<template>
  <!-- Documentación del botón en "vue-fab" -->
  <fab
    :position="position"
    :bg-color="background_color"
    :actions="fab_actions"
    title="Navegar a"
    tooltip="Navegar a"
    icon-size="small"
    position-type="fixed"
    @goToMatterProgram="goToMatterProgram"
    @goToEgressProfile="goToEgressProfile"
    @goToMatrix1="goToMatrix1"
    @goToMatrix2="goToMatrix2"
    @goToMatrix3="goToMatrix3"
    @goToMatrix4="goToMatrix4"
    @goToStudyPlan="goToStudyPlan"
    @goToCurricularMesh="goToCurricularMesh"
    @goToPlanning="goToPlanning"
  ></fab>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "MeshCustomFab",
  components: {
    fab: () => import("vue-fab"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    show_matter_program: {
      type: Boolean,
      default: true,
    },
    show_plannification: {
      type: Boolean,
      default: true,
    },
    // show_scheduler: {
    //   type: Boolean,
    //   default: true,
    // },
  },
  data() {
    return {
      egress_profile_id: null,
      background_color: "var(--header-color)",
      position: "bottom-right",
      fab_actions: [
        // {
        //   name: "goToScheduler",
        //   icon: "view_list",
        //   tooltip: "Calendario",
        //   color: "var(--secondary-color)",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      cycles: names.CYCLES,
    }),
  },
  methods: {
    goToMatterProgram() {
      this.$router.push({
        name: "MatterProgramView",
        params: { matter_id: this.matter_id },
      });
    },
    goToPlanning() {
      this.$router.push({
        name: "SessionPlanningView",
        params: {
          egress_profile_matter_id: this.matter_id,
          section_id: 0,
        },
      });
    },
    // goToScheduler() {
    //   this.$router.push({
    //     name: "SchedulerView",
    //     params: { matter_id: this.matter_id },
    //   });
    // },
    goToEgressProfile() {
      this.$router.push({
        name: "EgressProfileView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix1() {
      this.$router.push({
        name: "Matrix1View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix2() {
      this.$router.push({
        name: "Matrix2View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix3() {
      this.$router.push({
        name: "Matrix3View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToMatrix4() {
      this.$router.push({
        name: "Matrix4View",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToStudyPlan() {
      this.$router.push({
        name: "StudyPlanView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
    goToCurricularMesh() {
      this.$router.push({
        name: "CurricularMeshView",
        params: { egress_profile_id: this.egress_profile_id },
      });
    },
  },
  created() {
    if (this.cycles.length == 0) {
      this.$store.dispatch(names.FETCH_CYCLES);
    }
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, this.matter_id)
      .then((response) => {
        if (response) {
          this.egress_profile_id = response.egress_profile;
          this.fab_actions.push({
            name: "goToEgressProfile",
            icon: "school",
            tooltip: String(
              this.$getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil de Egreso"
              )
            ),
            color: "var(--secondary-color)",
          });
          this.fab_actions.push({
            name: "goToMatrix1",
            icon: "view_list",
            tooltip: String(
              this.$getVisibleNames("manual.matriz_1", false, "Matriz 1")
            ),
            color: "var(--secondary-color)",
          });
          this.fab_actions.push({
            name: "goToMatrix2",
            icon: "view_module",
            tooltip: this.$getVisibleNames(
              "manual.matriz_2",
              false,
              "Matriz 2"
            ),
            color: "var(--secondary-color)",
          });
          this.fab_actions.push({
            name: "goToMatrix3",
            icon: "view_quilt",
            tooltip: this.$getVisibleNames(
              "manual.matriz_3",
              false,
              "Matriz 3"
            ),
            color: "var(--secondary-color)",
          });
          // TODO: OCULTADO TABLA CAPACIDADES
          if (
            (this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
            this.$debug_change_duoc
          ) {
            this.fab_actions.push({
              name: "goToMatrix4",
              icon: "view_comfy",
              tooltip: this.$getVisibleNames(
                "manual.matriz_4",
                false,
                "Matriz 4"
              ),
              color: "var(--secondary-color)",
            });
          }
          this.fab_actions.push({
            name: "goToStudyPlan",
            icon: "public",
            tooltip: "Plan de Estudio",
            color: "var(--secondary-color)",
          });
          this.fab_actions.push({
            name: "goToCurricularMesh",
            icon: "dashboard",
            tooltip: "Malla Curricular",
            color: "var(--secondary-color)",
          });
        }
      });
    this.fab_actions = [];
    if (this.show_matter_program) {
      this.fab_actions.push({
        name: "goToMatterProgram",
        icon: "library_books",
        tooltip: this.$getVisibleNames(
          "manual.matter_program",
          false,
          "Programa de Asignatura"
        ),
        color: "var(--secondary-color)",
      });
    }
    // institution.id != 12 Para ocultar la planificación de la institución Duoc
    if (
      this.show_plannification &&
      this.institution &&
      this.institution.internal_use_id != "duoc_uc"
    ) {
      this.fab_actions.push({
        name: "goToPlanning",
        icon: "fact_check",
        tooltip: this.$getVisibleNames(
          "teaching.mattersessionplanning",
          false,
          "Planificación de la Asignatura"
        ),
        color: "var(--secondary-color)",
      });
    }
    if (this.show_scheduler) {
      this.fab_actions.push({
        name: "goToScheduler",
        icon: "view_module",
        tooltip: "Calendario",
        color: "var(--secondary-color)",
      });
    }
  },
};
</script>

<style scoped>
.fab {
  background-color: var(--header-color);
}
.fab-wrapper {
  bottom: 7vh !important;
  right: 0.7vw !important;
}
/* @media only screen and (max-width: 1100px) {
  .fab-wrapper {
    right: 0.7vw !important;
  }
}
@media only screen and (max-width: 900px) {
  .fab-wrapper {
    right: 0.7vw !important;
  }
}
@media only screen and (max-width: 700px) {
  .fab-wrapper {
    right: 0.7vw !important;
  }
} */
</style>